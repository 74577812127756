<template>
	<div class="index">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item>基础配置</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">招生管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == tabIndex ? 'active' : ''"
						v-for="(item, index) in tabMenu"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="head u-f-item" v-if="tabIndex!=2">
				<span class="span">选择条件</span>
				<el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="changeSchool">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select v-if="tabIndex==0" size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select v-if="tabIndex==0" size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<transition name="el-fade-in-linear">
				<template v-if="tabIndex==0">
					<admit @more="more" :condition="condition" :year="year" :semester="semester"></admit>
				</template>
				<template v-if="tabIndex==1">
					<!-- <classmanage @more="more" :condition="condition" :year="year" :semester="semester"></classmanage> -->
				</template>
				<template v-if="tabIndex==2">
					<!-- <levelmanage @more="more" :partList="conditionList"></levelmanage> -->
				</template>
			</transition>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import admit from '@/components/admit/admit.vue';
export default {
	components: {
		index,
		admit
	},
	data() {
		return {
			tabIndex: 0,
			tabMenu: [
				{name: '招生信息'},
				{name: '云表单管理'},
				{name: '权限配置'},
			],
			condition: 0,
			conditionList: [],
			year: '',
			yearList: [],
			semester: '',
			semesterList: []
		};
	},
	mounted() {
		this.schoolCampusIndex();
	},
	methods: {
		twacherTap(index) {
			this.gradeIndex = index;
		},
		more(index){
			this.gradeIndex = index;
		},
		// 校区列表
		schoolCampusIndex(){
			this.$api.setting.schoolCampusIndex().then(res=>{
				if(res.data.code==1){
					this.conditionList = res.data.data.rows;
					this.condition = this.conditionList[0].id;
					this.getYearList()
				}
			})
		},
		changeSchool(e){
			this.condition = e
			window.localStorage.setItem("campusid",e);
		},
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.yearList = res.data.data;
					if(res.data.data.length){
						this.year = this.yearList.filter(item=>item.is_default==2)[0].id;
					}else{
						this.year = ''
					}
					this.getSemesterList();
				}
			})
		},
		changeYear(e){
			this.year = e;
			this.getSemesterList()
		},
		// 学期列表
		getSemesterList(){
			this.$api.setting.getXueQiList({year_id: this.year}).then(res=>{
				if(res.data.code==1){
					this.semesterList = res.data.data;
					if(res.data.data.length){
						this.semester = this.semesterList.filter(item=>item.is_default==2)[0].id;
					}else{
						this.semester = '';
					}
				}
			})
		},
		changeSemester(e){
			this.semester = e;
		},
	}
};
</script>


<style lang="scss">
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	
</style>
